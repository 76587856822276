#root {
  background-color: #ffffff;
  position: relative;
  /* min-width: 90%; */
}

@media (min-width: 600px) {
  .action-link:hover {
    background-color: #ffffff1f !important;
  }

  .action-icons {
    font-size: 1.25rem;
  }
}

.bold {
  font-weight: bold;
}

.link:hover {
  text-decoration: underline;
}

a,
.action-link {
  color: inherit !important;
  text-decoration: none !important;
  transition: 0.3s all ease !important;
  width: 100%;
  justify-content: left !important;
  margin: 0 !important;
}

.copyright-appname {
  margin: 0 5px;
}

.action-icons {
  font-size: 1.5rem;
}

.carousel {
  display: flex;
  justify-content: center;
}

.carousel .slide img {
  max-height: 100vh;
  width: auto;
  /* object-fit: cover; */
  border-radius: 3px;
}

.category-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  margin-top: 1rem;
}

.category-box-container {
  width: 20%;
  margin: 0.5rem;
  overflow: hidden;
}

.circular-box {
  width: 15% !important;
}

.category-img-container {
  width: 100%;
  overflow: hidden;
}

.circular-img {
  border-radius: 50% !important;
}

.category-paper-container {
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  background-position: center;
  background-size: cover;
  -webkit-transition: transform 0.5s;
  transition: transform 0.5s;
  position: relative;
}

.category-paper-container::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.category-paper-container:hover {
  -webkit-transform: scale(1.07, 1.07);
  transform: scale(1.07, 1.07);
  -webkit-transition: transform 0.5s;
  transition: transform 0.5s;
}

.paper-heading {
  padding: 0.25rem;
  position: relative;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  text-align: center;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.paper-heading .text {
  font-weight: 400;
}

.product-box-container {
  /* width: 100%; */
  margin: 0;
  overflow: hidden;
  position: relative;
  display: block;
}

.product-paper-heading {
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  text-align: left;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.product-paper-heading .text {
  margin-top: 0.5rem;
  font-weight: 500;
}

.product-paper-heading .price {
  font-weight: 500;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  margin-top: 1rem;
}

.product-paper-container {
  cursor: pointer;
  width: 100%;
  background-position: center;
  background-size: cover;
  -webkit-transition: transform 0.5s;
  transition: transform 0.5s;
  position: relative;
  /* margin-bottom: 0.75rem; */
}

.bg-image::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.product-paper-container:hover {
  -webkit-transform: scale(1.04, 1.04);
  transform: scale(1.04, 1.04);
  -webkit-transition: transform 0.5s;
  transition: transform 0.5s;
}

.drawer-list-item {
  background-color: #ffffff1a;
  margin: 5px 0;
}

/* Multilevel Menu Starts */
.action-link-submenu {
  color: inherit !important;
  text-decoration: none !important;
  transition: 0.3s all ease !important;
  /* text-transform: capitalize !important; */
  justify-content: flex-start !important;
  width: 100% !important;
  padding: 0.5rem 1.5rem !important;
  margin: 0 !important;
}

.nav-area {
  display: flex;
  justify-content: left;
  max-width: 100%;
  margin: 0 auto;
}

.nav-area-mobile {
  display: flex;
  align-items: left;
  max-width: 100%;
  margin: 0 auto;
  flex-direction: column;
}

.menu-ul {
  margin: 0;
  padding: 0;
}

.menu-items {
  position: relative;
  list-style: none;
}

.icon-float {
  position: absolute !important;
  right: -20px;
  top: -20px;
  z-index: 1;
}

.favorite-icon {
  background-color: #ffffffd6 !important;
  right: 5px !important;
  top: 5px !important;
}

.favorite-icon-false {
  color: #afafaf !important;
}

.favorite-icon-true {
  color: rgb(206, 56, 56) !important;
}

/* .menu-items button {
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
} */

.dropdown {
  margin: 0;
  position: absolute;
  /* right: 0; */
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  /* font-size: 0.875rem; */
  z-index: 999;
  min-width: 10rem;
  width: max-content;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #f8f8f7;
  border-radius: 0 !important;
  /* display: none; */
  /* color: #0c6357 !important; */
  transition: 0.5s !important;
  opacity: 0;
  visibility: hidden;
}

.dropdown.show {
  transition: 0.5s;
  opacity: 1;
  visibility: visible;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

/* For Mobile */
.menu-items-mobile {
  position: relative;
  list-style: none;
  border-bottom: solid 1px #cccccc57;
  padding: 5px 0;
}

.dropdown-mobile {
  margin: 0;
  max-height: 0px;
  -webkit-transition: max-height, 0.5s linear !important;
  -moz-transition: max-height, 0.5s linear !important;
  -ms-transition: max-height, 0.5s linear !important;
  -o-transition: max-height, 0.5s linear !important;
  transition: max-height, 0.5s linear !important;
  overflow: hidden;
  position: relative;
  z-index: 999;
  min-width: 10rem;
  width: 100%;
  padding: 0;
  list-style: none;
}

.dropdown-mobile.show {
  max-height: 500px;
  -webkit-transition: max-height, 0.5s linear;
  -moz-transition: max-height, 0.5s linear;
  -ms-transition: max-height, 0.5s linear;
  -o-transition: max-height, 0.5s linear;
  transition: max-height, 0.5s linear;
  /* background-color: #ffffffcf; */
}

.dropdown-mobile .dropdown-submenu-mobile {
  position: relative;
  left: 10%;
  top: 0;
}

/* Multilevel Menu Ends */
.action-link-submenu p {
  font-weight: 400;
  font-size: 0.85rem;
}

.thumbs {
  padding-left: 0;
}

.addItemCount {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-item-img {
  background-position: center;
  background-size: cover;
  padding-bottom: 100%;
}

.cart-item-container {
  display: flex;
  /* padding: 1rem; */
}

.cart-item-price {
  color: gray;
}

.cart-subtotal-text {
  letter-spacing: 0.02rem;
  font-weight: 500 !important;
  color: gray;
}

.cart-subtotal-price {
  letter-spacing: 0.02rem;
  font-weight: 500 !important;
}

.small {
  font-size: 1rem !important;
}

.bold {
  font-weight: 500 !important;
}

.item-total-amount {
  font-size: 1.25rem;
}

/* .cart-item-delete-icon {
  position: relative !important;
  top: -25px;
  left: calc(100% - 15px);
  right: 0;
  font-size: 1.5rem !important;
  color: #f44336 !important;
  background-color: #9b9b9b52 !important;
} */
.no-scrollbar {
  /* white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll; */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.cart-item-paper {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.carousel .thumbs-wrapper {
  margin: 0 !important;
}

.drawer-appbar-action-icon {
  margin: 0 3px !important;
}

.drawer-appbar-action-icon:hover {
  background-color: #ffffff00 !important;
}

#loader {
  width: 100%;
}

.salebar-caption {
  font-weight: bold !important;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .5px;
}

.salebar-caption.icon-button {
  padding: 0;
  margin: 0 8px;
}

.salebar-caption.icon {
  transition: all 0.3s;
}

.salebar-caption.fb:hover {
  color: blue;
  transform: scale(1.);
}

.salebar-caption.icon:hover {
  transform: scale(1.1);
}

.salebar-caption.insta:hover {
  color: #962fbf;
}

.salebar-caption.yt:hover {
  color: #CD201F;
}

.salebar-caption.whatsapp:hover {
  color: #075e54;
}

/* .loader-container {
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0) url("https://i.gifer.com/CVZ.gif") center
    no-repeat;
  z-index: 1;
} */
/* .loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(152, 255, 224, 0.588);
  z-index: 0;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #6f737c transparent #6f737c transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}
.tracking-in-expand {
  color: #6f737c;
  font-size: 2rem;
  -webkit-animation: tracking-in-expand 1.7s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite alternate both;
  animation: tracking-in-expand 1.7s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    infinite alternate both;
} */
.phone-input {
  width: 100%;
  height: 56px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.23);
  padding-left: 1rem;
  font: inherit;
}

.phone-input:focus-visible {
  outline: 0;
  border-color: #6f737c !important;
  border-width: 2px;
}

.phone-input:hover {
  outline: 0;
  border-color: #000000;
}

.MuiDataGrid-cell {
  overflow: auto !important;
  white-space: nowrap;
  text-overflow: ellipsis !important;
}

.max-price {
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  color: gray;
  font-size: 1rem;
  font-weight: 500;
}

.list-price {
  margin-right: 0.5rem !important;
  font-size: 1.25rem;
  font-weight: 500;
}

.discount-percent {
  margin-right: 1rem !important;
  font-size: 1rem;
  text-transform: lowercase;
  font-weight: 500;
}

.mrp-text.small {
  font-size: 0.75rem;
}

.list-price.small {
  margin-right: 0.5rem !important;
  font-size: 0.85rem;
  font-weight: 500;
}

.discount-percent.small {
  margin-right: 0.5rem !important;
  font-size: 0/75rem;
  text-transform: lowercase;
  font-weight: 500;
}

.save-rupees {
  background-color: lightgreen;
  color: #075e54;
  border-radius: 3px;
  padding: 2px 10px;
  font-size: 0.75rem !important;
  display: block;
  width: max-content;
  font-weight: 500 !important;
}

.list-price-detail {
  font-size: 1.5rem;
  font-weight: 500;
  margin-right: 1rem !important;
}

.discount-percent-detail {
  margin-right: 0.5rem !important;
  font-size: 1.25rem;
  font-weight: 400;
  color: #a62323;
}

.online {
  background-color: #99fa99;
  color: darkgreen;
}

.offline {
  background-color: #ffd2d2;
  color: darkred;
}

.centered-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-button {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  -webkit-transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding-top 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding-top 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 0px 12px;
  min-width: 80px;
  max-width: 168px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 70px;
}

.tab-button-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.75rem;
  opacity: 1;
  -webkit-transition: font-size 0.2s, opacity 0.2s;
  transition: font-size 0.2s, opacity 0.2s;
  transition-delay: 0.1s;
}

.tab-button.selected {}

.project-item,
.project-item .project-img {
  position: relative;
}

.project-item .project-img a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  opacity: 0;
  transition: 0.5s;
}

.project-item:hover .project-img a {
  opacity: 1;
}

.project-item .project-title {
  position: absolute;
  top: 3rem;
  right: 0;
  bottom: 0;
  left: 3rem;
  border: 1px solid var(--light);
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  padding: 18px;
  z-index: -1;
  transition: 0.5s;
}

.project-item:hover .project-title {
  background: var(--primary);
  border-color: var(--primary);
}

.project-item .project-title h4 {
  transition: 0.5s;
}

.project-item:hover .project-title h4 {
  color: #ffffff;
}

.project-carousel .owl-nav {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.project-carousel .owl-nav .owl-prev,
.project-carousel .owl-nav .owl-next {
  margin: 0 12px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  background: var(--light);
  border-radius: 45px;
  font-size: 22px;
  transition: 0.5s;
}

.project-carousel .owl-nav .owl-prev:hover,
.project-carousel .owl-nav .owl-next:hover {
  background: var(--primary);
  color: var(--light);
}

.reviews-container {
  /* background-color: #075e54; */
  padding-left: 1rem;
  padding-right: 1rem;
  /* color: white; */
  border-radius: 3px;
}

.MuiCardHeader-title {
  font-size: 1.25rem !important;
}

.MuiCardHeader-subheader {
  /* color: #f8f8f7 !important; */
  font-weight: 500 !important;
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.full-height {
  min-height: calc(100vh - 64px);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.address-box {
  min-height: 50px;
  width: 100%;
  padding: 0.5rem 0.75rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.product-img {
  width: 100% !important;
  aspect-ratio: 1;
}

@media (width <=340px) {
  .salebar-caption {
    font-weight: 500 !important;
    text-align: center;
    text-transform: none;
    letter-spacing: .5px;
    font-size: 0.65rem !important;
  }

  .list-price-detail,
  .list-price {
    font-size: 1rem !important;
    margin-right: 0.5rem !important;
  }

  .discount-percent {
    display: none;
  }

  .discount-percent-detail {
    font-size: 1rem !important;
  }

  .mrp-text {
    display: none;
  }

  .max-price {
    font-size: 0.75rem !important
  }

  .cart-item-title {
    font-size: 1rem !important;
  }

  .cart-subtotal-price {
    font-size: 0.75rem !important;
  }

  .cart-item-price {
    font-size: 0.75rem !important;
    letter-spacing: 1;
  }

  .item-total-amount {
    font-size: 0.85rem !important;
  }

  .circular-box {
    width: 40% !important;
  }

  .full-height {
    min-height: calc(100vh - 96px);
    padding-top: 0 !important;
    padding-bottom: 1rem;
  }


}

@media (340px < width <=420px) {
  .salebar-caption {
    font-weight: 500 !important;
    text-align: center;
    text-transform: none;
    letter-spacing: .5px;
    font-size: 0.65rem;
  }

  .list-price-detail,
  .list-price {
    font-size: 1rem !important;
    margin-right: 0.5rem !important;
  }

  .discount-percent {
    display: none;
  }

  .discount-percent-detail {
    font-size: 1rem !important;
  }

  .mrp-text {
    display: none;
  }

  .max-price {
    font-size: 0.75rem !important
  }

  .cart-item-title {
    font-size: 1rem !important;
  }

  .cart-item-price {
    font-size: 0.9rem !important;
  }

  .item-total-amount {
    font-size: 1rem !important;
  }

  .circular-box {
    width: 35% !important;
  }

  .circular-box-search-result {
    width: 40% !important;
  }

  .full-height {
    min-height: calc(100vh - 96px);
    padding-top: 0 !important;
    padding-bottom: 1rem;
  }
}

@media (900px > width > 420px) {

  /* .cart-item-title {
    font-size: 0.85rem !important;
  } */
  /* .cart-item-price {
    font-size: 0.7rem !important;
  } */
  .item-total-amount {
    font-size: 1.2rem !important;
  }

  .circular-box {
    width: 25% !important;
  }

  .full-height {
    min-height: calc(100vh - 96px);
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media (width >=900px) {}